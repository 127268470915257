export const COMPLETE_STEP = 'COMPLETE_STEP';
export const CONTENT_STEP = 'CONTENT_STEP';
export const HOTEL_INFO = 'HOTEL_INFO';
export const SET_PRELOADER = 'SET_PRELOADER';
export const PROPERTY_TYPE  = "PROPERTY_TYPE";

// set current Step and next Step
export const completeStep = () => ({ type: COMPLETE_STEP });

// set current Step
export const currentStep = (step) => ({ type: CONTENT_STEP, payload: { step } });

// set Hotel Information 
export const setHotelInfo = (hotel) => ({ type: HOTEL_INFO, payload: { hotel } });

// set Loader on/Off
export const setPreLoader = () => ({ type: SET_PRELOADER });

export const setPropertyType = (data) => ({ type: PROPERTY_TYPE, payload: { data } });



