import {
  IconLayoutDashboard,
  IconBrowserCheck,
  IconCoinRupee,
  IconListCheck,
}
  from '@tabler/icons';
// constant
const icons = {
  IconLayoutDashboard, IconBrowserCheck, IconCoinRupee, IconListCheck,
};
// import Cookies from 'js-cookie';
// let is_verified = JSON.parse(Cookies.get('user') || '{}');
// console.log(is_verified.role);

/********************************HOTEL DASHBOARD NAV ITEM************************************/

const hotelDashboard = {
  id: 'Dashboard',
  title: '',
  caption: 'Dashboard',
  type: 'group',
  children: [
    {
      id: 'hotel',
      title: 'Dashboard',
      type: 'item',
      url: '/hotel/dashboard',
      icon: icons.IconLayoutDashboard,
      breadcrumbs: false
    },
    {
      id: 'Bookings',
      title: 'Bookings',
      type: 'item',
      url: '/hotel/bookings',
      icon: icons.IconBrowserCheck,
      breadcrumbs: false
    },

    {
      id: 'rates and inventories',
      title: 'Rates and Inventory',
      type: 'collapse',
      icon: icons.IconListCheck,
      children: [
        {
          id: 'inventory',
          title: 'Inventory',
          type: 'item',
          url: '/hotel/inventory',
        },
        // {
        //   id: 'price',
        //   title: 'Price List',
        //   type: 'item',
        //   url: 'hotel/price-list',
        // },
      ]
    },
    {
      id: 'payments',
      title: 'Payments',
      type: 'item',
      url: '/hotel/payments',
      icon: icons.IconCoinRupee,
      breadcrumbs: false
    },
    {
      id: 'promotions',
      title: 'Promotions',
      type: 'item',
      url: '/hotel/promotions',
      icon: icons.IconCoinRupee,
      breadcrumbs: false
    },
    {
      id: 'insight',
      title: 'Competitors analysis',
      type: 'item',
      url: '/hotel/insight-trends',
      icon: icons.IconCoinRupee,
      breadcrumbs: false
    },

    {
      id: 'property',
      title: 'Property',
      type: 'collapse',
      icon: icons.IconListCheck,
      children: [
        {
          id: 'basic-info',
          title: 'Basic Info',
          type: 'item',
          url: '/hotel/basic-info',
        },
        {
          id: 'rooms',
          title: 'Rooms',
          type: 'item',
          url: '/hotel/rooms',
        },
        {
          id: 'photos',
          title: 'Photos',
          type: 'item',
          url: '/hotel/photos',
        },
        {
          id: 'policies',
          title: 'Policies',
          type: 'item',
          url: '/hotel/policies',
        },
        {
          id: 'Near By Place',
          title: 'Near By Place',
          type: 'item',
          url: '/hotel/near-by-place',
        },
        {
          id: 'financial-details',
          title: 'Financial Details',
          type: 'item',
          url: '/hotel/financial-details',
        },
      ]
    },
  ]
}
/********************************HOTEL DASHBOARD NAV ITEM************************************/


const menuItems = {
  items: [hotelDashboard]
};

export default menuItems;
