import { Outlet, useLocation } from 'react-router-dom';

// material-ui
import { styled } from '@mui/material/styles';

// material-ui
import { Grid } from '@mui/material';

// project imports
import AuthFooter from 'layout/AuthLayout/AuthFooter';
import { useEffect } from "react"
import { useNavigate } from "react-router-dom"
import Cookies from 'js-cookie';

// ==============================|| AUTHENTICATION 1 WRAPPER ||============================== //

const AuthWrapper = styled('div')(({ theme }) => ({
    backgroundColor: theme.palette.primary.light,
    minHeight: '100vh'
}));

const AuthLayout = () => {
    let isLoggedIn = Cookies.get('_session');
    let is_verified = Cookies.get('is_verified');
    const navigate = useNavigate();
    const location = useLocation()
    const pathname = location.pathname;
    const splitPath = pathname.split('/');
    useEffect(() => {
        if (isLoggedIn) {
            if (is_verified == 'true') {
                navigate('/')
            } else {
                if (splitPath[1] != 'verify-email')
                    navigate('/verify-account')
            }
        } else {
            if (splitPath[1] != 'forgot-password' && splitPath[1] != 'change-password')
                navigate('/login')
        }
    }, [])


    // useEffect(() => isLoggedIn && navigate('/'), [isLoggedIn]);
    return (
        <AuthWrapper>
            <Grid container direction="column" justifyContent="flex-end" sx={{ minHeight: '100vh' }}>
                <Grid item xs={12}>
                    <Grid container justifyContent="center" alignItems="center" sx={{ minHeight: 'calc(100vh - 68px)' }}>
                        <Grid item sx={{ m: { xs: 1, sm: 3 }, mb: 0 }}>
                            <Outlet />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} sx={{ m: 3, mt: 1 }}>
                    <AuthFooter />
                </Grid>
            </Grid>
        </AuthWrapper>)
}

export default AuthLayout;
