import { SET_AUTH } from '../action/authAction';
const initialState = {
  userInfo: {},
};
export const authReducer = (state = initialState, action) => {
  const { payload } = action;
  switch (action.type) {
    case SET_AUTH:
      return {
        ...state,
        userInfo: payload.detail
      };
    default:
      return state;
  }
};
