import { combineReducers } from 'redux';

// reducer import
import customizationReducer from './customizationReducer';
import hotelReducer from './reducers/hotelReducer';
import { authReducer } from './reducers/authReducer';

// ==============================|| COMBINE REDUCER ||============================== //

const reducer = combineReducers({
  customization: customizationReducer,
  hotelReducer: hotelReducer,
  authReducer: authReducer,
});

export default reducer;
