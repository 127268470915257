import { lazy } from 'react';
import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';

const SamplePage = Loadable(lazy(() => import('views/sample-page')));
// const Properties = Loadable(lazy(() => import('views/properties')));
const HotelList = Loadable(lazy(() => import('views/hotel-list')));
const PropertyType = Loadable(lazy(() => import('views/property-type')));
const BasicInfo = Loadable(lazy(() => import('views/hotel/basic-info')));
const HotelSEO = Loadable(lazy(() => import('views/hotel/hotel-seo')));
const HotelContent = Loadable(lazy(() => import('views/hotel/hotel-content')));
const HotelImages = Loadable(lazy(() => import('views/hotel/hotel-images')));
const HotelRooms = Loadable(lazy(() => import('views/hotel/hotel-rooms')));
const HotelNearbyPlaces = Loadable(lazy(() => import('views/hotel/hotel-nearby-places')));
const HotelPolicy = Loadable(lazy(() => import('views/hotel/hotel-policy')));
const HotelFinance = Loadable(lazy(() => import('views/hotel/hotel-finance')));
const Profile = Loadable(lazy(() => import('views/pages/profile')));
const ChangePassword = Loadable(lazy(() => import('views/pages/profile/ChangePassword')));
const Coupons = Loadable(lazy(() => import('views/coupons')));

const MainRoutes = {
  path: '/',
  element: <MainLayout />,
  children: [
    {
      path: '/',
      element: <HotelList />
    },
    // {
    //   path: 'properties',
    //   element: <Properties />
    // },
    {
      path: 'hotel',
      element: <HotelList />
    },
    {
      path: 'hotel/hotel-type',
      element: <PropertyType />
    },
    {
      path: 'hotel/create',
      element: <BasicInfo />
    },
    {
      path: 'hotel/hotel-seo',
      element: <HotelSEO />
    },
    {
      path: 'hotel/hotel-content',
      element: <HotelContent />
    },
    {
      path: 'hotel/hotel-images',
      element: <HotelImages />
    },
    {
      path: 'hotel/hotel-rooms',
      element: <HotelRooms />
    },
    {
      path: 'hotel/hotel-nearby-places',
      element: <HotelNearbyPlaces />
    },
    {
      path: 'hotel/hotel-policy',
      element: <HotelPolicy />
    },
    {
      path: 'hotel/hotel-finance',
      element: <HotelFinance />
    },
    {
      path: 'profile',
      element: <Profile />
    },
    {
      path: 'change-password',
      element: <ChangePassword />
    },
    {
      path: 'sample-page',
      element: <SamplePage />
    },
    {
      path: 'coupons',
      element: <Coupons />
    },
  ]
};
export default MainRoutes;
