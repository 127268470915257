import * as React from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { useEffect } from 'react';
import { setPreLoader } from 'store/hotelAction';
import HotelService from "services/HotelService";
import { useDispatch } from 'react-redux';
import { useState } from 'react';
import { useNavigate, useParams } from 'react-router';

export default function SearchSection() {

  const { hotelId } = useParams();
  const navigate = useNavigate();
  const [hotels, setHotels] = useState([]);
  const [selectedHotel, setSelectedHotel] = useState(null);

  const dispatch = useDispatch();

  useEffect(() => {
    const getActiveHotel = async () => {
      dispatch(setPreLoader());
      try {
        const result = await HotelService().getAllSearchHotels();
        if (result.success) {
          let data = result?.data;
          setHotels(data.map((hotel) => ({ id: hotel.id, name: hotel.name })));
        }
      } catch (error) {
        console.log("error", error)
      }
      dispatch(setPreLoader());
    }

    if (hotels.length === 0) {
      getActiveHotel();
    }
  }, []);

  useEffect(() => {
    // Find selected hotel based on hotelId
    const selectedHotelObj = hotels.find(hotel => hotel.id === parseInt(hotelId));
    setSelectedHotel(selectedHotelObj);
  }, [hotelId, hotels]);

  const navigateToDashboard = (event, newValue) => {
    if (newValue) {
      navigate(`/hotel/dashboard/${newValue.id}`);
      window.location.reload();
    }
  }

  return (
    <Autocomplete
      disablePortal
      id="combo-box-demo"
      value={selectedHotel} // Set the value to the selected hotel object
      options={hotels}
      getOptionLabel={(option) => option.name}
      sx={{ width: 350, marginLeft: 2 }}
      renderInput={(params) => <TextField {...params} label="Hotels" />}
      onChange={navigateToDashboard}
    />
  );
}
