import axios from 'axios';
import { UNEXPECTED_RESPONSE_STATUS } from '../store/constant';
import { AuthApiService } from './AuthApiService';
import { ToastErrorMessage, ToastSuccessMessage } from './ToastMessageService';
import { BASE_URL } from './config';
// import ApiService from './ApiService';

import Cookies from 'js-cookie';

function HotelService() {
    let authTokenLocal = JSON.parse(Cookies.get('_session') || '{}');
    if (authTokenLocal === "") {
        authTokenLocal = localStorage.getItem("authtoken");
    }
    let service = axios.create({
        baseURL: BASE_URL,
        headers: {
            Authorization: `Bearer ${AuthApiService.getToken()}`,
            'Content-Type': 'application/json'
        }
    });

    const userId = AuthApiService.getUserId();
    const resultResponse = (response) => {
        if (response.status === 200) {
            ToastSuccessMessage(response?.data?.message)
            return { success: true, data: response.data };
        } else {
            ToastSuccessMessage(UNEXPECTED_RESPONSE_STATUS);
            return {
                success: false,
                error: `${UNEXPECTED_RESPONSE_STATUS}: ${response.status}`
            };
        }
    };
    const resultFieldResponse = (error) => {

        if (error.response) {
            ToastErrorMessage(error?.response?.data?.data);
            return { success: false, errors: error?.response?.data?.data };
        } else {
            ToastErrorMessage("Something went wrong");
            return { success: false, error: 'Something went wrong' };
        }
    };

    const getAllDraftHotels = async (data, page) => {
        try {
            const response = await service.post(`hotel/index?page=${page}`, data);
            return resultResponse(response);
        } catch (error) {
            return resultFieldResponse(error);
        }
    };
    const getAllListItem = async () => {
        try {
            const response = await service.post(`list-item/hotel`);
            return resultResponse(response);
        } catch (error) {
            return resultFieldResponse(error);
        }
    };

    const hotelCreate = async (data) => {
        try {
            data.owner_id = userId;
            const response = await service.post(`hotel/create`, data);
            return resultResponse(response);
        } catch (error) {
            return resultFieldResponse(error);
        }
    };

    const hotelDelete = async (data) => {
        try {
            const response = await service.post(`hotel/destroy`, data);
            return resultResponse(response);
        } catch (error) {
            return resultFieldResponse(error);
        }
    };
    const getHotelById = async (data) => {
        try {
            const response = await service.post(`hotel/get-by-id`, data);
            return resultResponse(response);
        } catch (error) {
            return resultFieldResponse(error);
        }
    };
    // get All coupon
    const getAllCoupons = async (data) => {
        try {
            data.userId = userId;
            const response = await service.post(`coupon/index`, data);
            return resultResponse(response);
        } catch (error) {
            return resultFieldResponse(error);
        }
    };
    const createCoupon = async (data) => {
        try {
            data.created_by = userId;
            const response = await service.post(`coupon/create`, data);
            return resultResponse(response);
        } catch (error) {
            return resultFieldResponse(error);
        }
    };
    const deleteCoupon = async (data) => {
        try {
            data.created_by = userId;
            const response = await service.post(`coupon/destroy`, data);
            return resultResponse(response);
        } catch (error) {
            return resultFieldResponse(error);
        }
    };

    const getAllPromotions = async (data) => {
        try {
            const response = await service.post(`promotion/index`, data);
            return resultResponse(response);
        } catch (error) {
            return resultFieldResponse(error);
        }
    };

    const createPromotion = async (data) => {
        try {
            const response = await service.post(`promotion/create`, data);
            return resultResponse(response);
        } catch (error) {
            return resultFieldResponse(error);
        }
    };
    const deletePromotion = async (data) => {
        try {
            const response = await service.post(`promotion/destroy`, data);
            return resultResponse(response);
        } catch (error) {
            return resultFieldResponse(error);
        }
    };
    const getPromotion = async (data) => {
        try {
            const response = await service.post(`promotion/get-by-id`, data);
            return resultResponse(response);
        } catch (error) {
            return resultFieldResponse(error);
        }
    };

    const getAllSearchHotels = async () => {
        try {
            const response = await service.post(`hotel/list`);
            return resultResponse(response);
        } catch (error) {
            return resultFieldResponse(error);
        }
    }

    const getDashboardData = async (data) => {
        try {
            const response = await service.post(`hotel/dashboard`, data);
            return resultResponse(response);
        } catch (error) {
            return resultFieldResponse(error);
        }
    }
    const addToCompare = async (data) => {
        try {
            const response = await service.post(`add-to-compare`, data);
            return resultResponse(response);
        } catch (error) {
            return resultFieldResponse(error);
        }
    }
    const getCompareList = async (data) => {
        try {
            const response = await service.post(`get-compared-list`, data);
            return resultResponse(response);
        } catch (error) {
            return resultFieldResponse(error);
        }
    }

    const getComparedResult = async (data) => {
        try {
            const response = await service.post(`get-compared-result`, data);
            return resultResponse(response);
        } catch (error) {
            return resultFieldResponse(error);
        }
    }
    const fetchSuggestions = async (data) => {
        try {
            const response = await service.post(`get-banks`, data);
            return resultResponse(response);
        } catch (error) {
            return resultFieldResponse(error);
        }
    }



    return {
        getAllSearchHotels,
        getDashboardData, fetchSuggestions,
        getAllDraftHotels,
        getAllListItem,
        hotelCreate,
        hotelDelete,
        getAllCoupons,
        createCoupon,
        deleteCoupon, getComparedResult,
        getAllPromotions, getCompareList, addToCompare,
        createPromotion, deletePromotion, getPromotion,
        getHotelById
    };
}
export default HotelService;
