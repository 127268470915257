import React from 'react'
import { Backdrop, CircularProgress } from '@mui/material';
import { useSelector } from "react-redux";

function PreLoader() {
    const hotelReducer = useSelector((state) => state.hotelReducer);
    return (
        <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={hotelReducer.loading}
        >
            <CircularProgress color="inherit" />
        </Backdrop>
    )
}

export default PreLoader