import { lazy } from 'react';

// project imports
import Loadable from 'ui-component/Loadable';
import AuthLayout from 'layout/AuthLayout';

// login option 3 routing
const AuthLogin = Loadable(lazy(() => import('views/pages/authentication/authentication/Login')));
const AuthRegister = Loadable(lazy(() => import('views/pages/authentication/authentication/Register')));
const VerifyAccount = Loadable(lazy(() => import('views/pages/authentication/authentication/VerifyAccount')));
const EmailVerification = Loadable(lazy(() => import('views/pages/authentication/authentication/EmailVerification')));
const ForgotPassword = Loadable(lazy(() => import('views/pages/authentication/authentication/ForgotPassword/ForgotPasswordEmailSend')));
const SetNewPassword = Loadable(lazy(() => import('views/pages/authentication/authentication/ForgotPassword/SetNewPassword')));

// ==============================|| AUTHENTICATION ROUTING ||============================== //

const AuthenticationRoutes = {
  path: '/',
  element: <AuthLayout />,
  children: [
    {
      path: '/login',
      element: <AuthLogin />
    },
    {
      path: '/register',
      element: <AuthRegister />
    },
    {
      path: '/verify-account',
      element: <VerifyAccount />
    },
    {
      path: '/verify-email/:email/:token',
      element: <EmailVerification />
    },
    {
      path: '/forgot-password',
      element: <ForgotPassword />
    },
    {
      path: '/change-password/:email/:token',
      element: <SetNewPassword />
    }
    // {
    //   path: '/pages/login/login',
    //   element: <AuthLogin />
    // },
    // {
    //   path: '/pages/register/register',
    //   element: <AuthRegister />
    // }
  ]
};

export default AuthenticationRoutes;
