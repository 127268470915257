// material-ui
import { Link, Typography, Stack } from '@mui/material';

// ==============================|| FOOTER - AUTHENTICATION 2 & 3 ||============================== //

const AuthFooter = () => (
  <Stack direction="row" >
    <Typography variant="subtitle2" component={Link} href="https://bookyourown.net" target="_blank" underline="hover">
      &copy; Bookyourown.net
    </Typography>
  </Stack>
);

export default AuthFooter;
